import React from "react"
import PageLayout from "../components/page-layout"
import Message from "../components/message"

import Signature from "../images/signature.png"
import YukiMamiya from "../images/yukimamiya.jpg"

export default function Home() {
    return (
        <PageLayout title="ごあいさつ">
            <div className="greeting">
                <div className="greeting-container">
                    <div className="profile-img">
                        <img src={YukiMamiya} alt="眞宮 由妃" />
                    </div>
                    <div className="message-wrapper">
                        <Message>
                            {`私が幼かった頃、バレエが楽しくて楽しくて、次のレッスンまでの１週間が待ちきれなかった記憶があります。
                    お稽古場では小さいながらも皆が真剣で、おふざけが許されない、どこか心地よい緊張感がありました。
                    やがて大きくなってくると、ひとつずつ新しいテクニックを教わる度に、何度も繰り返し練習してやっとできた時の達成感が何とも言えない大きな喜びになっていきました。
                    今でもバーを握って立つ時、あの頃の感覚を思い出します。
                    大切な、とても幸せな記憶です。

                    私の先生方がして下さった事を皆さんにお渡ししていきたい、あの頃の私のような、バレエを楽しく学ぶ子どもたちが集まる安心して通えるスタジオにしていきたいと思っています。`}
                        </Message>
                        <div className="signature">
                            <img src={Signature} alt="眞宮 由妃" />
                        </div>
                    </div>
                </div>
            </div>
        </PageLayout >
    )
}